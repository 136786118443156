"use strict";

var $left = $(".left");
var $gl = $(".gallery");
var $gl2 = $(".gallery2");
var $photosCounterFirstSpan = $(".photos-counter span:nth-child(1)");

$gl2.on("init", function (event, slick) {
  $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
  $(".photos-counter span:nth-child(2)").text(slick.slideCount);
});

$gl.slick({
  rows: 0,
  slidesToShow: 2,
  arrows: false,
  draggable: false,
  useTransform: false,
  mobileFirst: true,
  responsive: [{
    breakpoint: 480,
    settings: {
      slidesToShow: 3 }
  }]
});

$gl2.slick({
  rows: 0,
  useTransform: false,
  prevArrow: ".arrow-left",
  nextArrow: ".arrow-right",
  fade: true,
  asNavFor: $gl });

$(".gallery .item").on("click", function () {
  var index = $(this).attr("data-slick-index");
  $gl2.slick("slickGoTo", index);
});

$gl2.on("afterChange", function (event, slick, currentSlide) {
  $photosCounterFirstSpan.text(slick.currentSlide + 1 + "/");
});