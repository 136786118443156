"use strict";

!function (a, b, c, d) {
  "use strict";
  function e(a) {
    return a.getMonth() + 12 * a.getFullYear();
  }function f(a) {
    return Math.floor(a / 12);
  }function g() {
    a(this).addClass(z);
  }function h(a, b) {
    return a[b ? "on" : "off"]("mousenter mouseout", g).toggleClass(z, b);
  }function i(a, b, c) {
    return (!b || a >= b) && (!c || c >= a);
  }function j(b, c) {
    if (null === c) return c;if (c instanceof d) return e(c);if (a.isNumeric(c)) return e(new d()) + parseInt(c, 10);var f = b._parseMonth(c);return f ? e(f) : l(c);
  }function k(a, b) {
    return L(b.options[a] || K, b.element[0]);
  }function l(b, c) {
    var f = a.trim(b);f = f.replace(/y/i, '":"y"'), f = f.replace(/m/i, '":"m"');try {
      var g = JSON.parse('{"' + f.replace(/ /g, ',"') + "}"),
          h = {};for (var i in g) {
        h[g[i]] = i;
      }var j = e(new d());return j += parseInt(h.m, 10) || 0, j + 12 * (parseInt(h.y, 10) || 0);
    } catch (k) {
      return !1;
    }
  }function m(b) {
    return a('<span id="MonthPicker_Button_' + this.id + '" class="month-picker-open-button">' + b.i18n.buttonText + "</span>").jqueryUIButton({ text: !1, icons: { primary: b.ButtonIcon } });
  }function n(a, b) {
    a.jqueryUIButton("option", { icons: { primary: "ui-icon-circle-triangle-" + (b ? "w" : "e") } });
  }function o(a) {
    return !a.is("input");
  }function p(b, c) {
    function d() {
      j = setTimeout(e, 175);
    }function e() {
      j = null, l = a("span", b).animate({ opacity: .45 }, k, f);
    }function f() {
      i = l.text(), l.animate({ opacity: 1 }, k).text(c);
    }function g() {
      j ? clearTimeout(j) : l = a("span", b).animate({ opacity: .45 }, k, h);
    }function h() {
      l.text(i).animate({ opacity: 1 }, k);
    }var i,
        j,
        k = 125,
        l = a();b.on("mouseenter" + t + "h", d), b.on("mouseleave" + t + "h", g), b.data(v, function () {
      clearTimeout(j), l.stop().css({ opacity: 1 }), b.off(t + "h");
    });
  }function q(a, b) {
    var c = a.data("ui-button");c.option("disabled") !== b && c.option("disabled", b);
  }var r = "MonthPicker Error: ";if (!(a && a.ui && a.ui.button && a.ui.datepicker)) return void alert(r + "The jQuery UI button and datepicker plug-ins must be loaded.");a.widget.bridge("jqueryUIButton", a.ui.button);var s = a.fx.speeds,
      t = ".MonthPicker",
      u = "month-year-input",
      v = "month-picker-clear-hint",
      w = ".ui-button-icon-primary",
      x = "month-picker-disabled",
      y = "ui-state-highlight",
      z = "ui-state-active",
      A = "ui-state-default",
      B = { my: "left top+1", at: "left bottom" },
      C = { my: "right top+1", at: "right bottom" },
      D = r + "The jQuery UI position plug-in must be loaded.",
      E = r + "Unsupported % option value, supported values are: ",
      F = r + '"_" is not a valid %Month value.',
      G = null,
      H = !!a.ui.position,
      I = { Animation: ["slideToggle", "fadeToggle", "none"], ShowAnim: ["fadeIn", "slideDown", "none"], HideAnim: ["fadeOut", "slideUp", "none"] },
      J = { ValidationErrorMessage: "_createValidationMessage", Disabled: "_setDisabledState", ShowIcon: "_updateButton", Button: "_updateButton", ShowOn: "_updateFieldEvents", IsRTL: "_setRTL", AltFormat: "_updateAlt", AltField: "_updateAlt", StartYear: "_setPickerYear", MinMonth: "_setMinMonth", MaxMonth: "_setMaxMonth", SelectedMonth: "_setSelectedMonth" },
      K = a.noop,
      L = a.proxy,
      M = a.datepicker,
      N = "click" + t;a.MonthPicker = { VERSION: "3.0.4", i18n: { year: "Year", prevYear: "Previous Year", nextYear: "Next Year", next12Years: "Jump Forward 12 Years", prev12Years: "Jump Back 12 Years", nextLabel: "Next", prevLabel: "Prev", buttonText: "Open Month Chooser", jumpYears: "Jump Years", backTo: "Back to", months: ["Jan.", "Feb.", "Mar.", "Apr.", "May", "June", "July", "Aug.", "Sep.", "Oct.", "Nov.", "Dec."] } };var O = '<div class="ui-widget-header month-picker-header ui-corner-all"><table class="month-picker-year-table"><tr><td class="month-picker-previous"><a /></td><td class="month-picker-title"><a /></td><td class="month-picker-next"><a /></td></tr></table></div><div><table class="month-picker-month-table" /></div>';a.widget("KidSysco.MonthPicker", { options: { i18n: {}, IsRTL: !1, Position: null, StartYear: null, ShowIcon: !0, UseInputMask: !1, ValidationErrorMessage: null, Disabled: !1, MonthFormat: "mm/yy", Animation: "fadeToggle", ShowAnim: null, HideAnim: null, ShowOn: null, MinMonth: null, MaxMonth: null, Duration: "normal", Button: m, ButtonIcon: "ui-icon-calculator" }, _monthPickerButton: a(), _validationMessage: a(), _selectedBtn: a(), _destroy: function _destroy() {
      var b = this.element;a.mask && this.options.UseInputMask && (b.unmask(), this.GetSelectedDate() || b.val("")), b.removeClass(u).off(t), a(c).off(t + this.uuid), this._monthPickerMenu.remove();var d = this._monthPickerButton.off(N);this._removeOldBtn && d.remove(), this._validationMessage.remove(), G === this && (G = null);
    }, _setOption: function _setOption(b, c) {
      switch (b) {case "i18n":
          c = a.extend({}, c);break;case "Position":
          if (!H) return void alert(D);break;case "MonthFormat":
          var d = this.GetSelectedDate();d && this.element.val(this.FormatMonth(d, c));}return b in I && -1 === a.inArray(c, I[b]) ? void alert(E.replace(/%/, b) + I[b]) : (this._super(b, c), void (J[b] ? this[J[b]](c) : 0));
    }, _create: function _create() {
      var b = this.element,
          e = this.options,
          g = b.attr("type");if (!b.is("input,div,span") || "text" !== g && "month" !== g && void 0 !== g) {
        var h = r + "MonthPicker can only be called on text or month inputs.";return alert(h + " \n\nSee (developer tools) for more details."), console.error(h + "\n Caused by:"), console.log(b[0]), !1;
      }if (!a.mask && e.UseInputMask) return alert(r + "The UseInputMask option requires the Input Mask Plugin. Get it from digitalbush.com"), !1;if (null !== e.Position && !H) return alert(D), !1;for (var i in I) {
        if (null !== e[i] && -1 === a.inArray(e[i], I[i])) return alert(E.replace(/%/, i) + I[i]), !1;
      }this._isMonthInputType = "month" === b.attr("type"), this._isMonthInputType && (this.options.MonthFormat = this.MonthInputFormat, b.css("width", "auto"));var k = this._monthPickerMenu = a('<div id="MonthPicker_' + b[0].id + '" class="month-picker ui-widget ui-widget-content ui-corner-all"></div>').hide(),
          l = o(b);a(O).appendTo(k), k.appendTo(l ? b : c.body), this._titleButton = a(".month-picker-title", k).click(L(this._showYearsClickHandler, this)).find("a").jqueryUIButton().removeClass(A), this._applyJumpYearsHint(), this._createValidationMessage(), this._prevButton = a(".month-picker-previous>a", k).jqueryUIButton({ text: !1 }).removeClass(A), this._nextButton = a(".month-picker-next>a", k).jqueryUIButton({ text: !1 }).removeClass(A), this._setRTL(e.IsRTL), a(w, this._nextButton).text(this._i18n("nextLabel")), a(w, this._prevButton).text(this._i18n("prevLabel"));for (var m = a(".month-picker-month-table", k), n = 0; 12 > n; n++) {
        var p = n % 3 ? p : a("<tr />").appendTo(m);p.append('<td><a class="button-' + (n + 1) + '" /></td>');
      }this._buttons = a("a", m).jqueryUIButton(), k.on("mousedown" + t, function (a) {
        a.preventDefault();
      });var q = this,
          s = "Month";a.each(["Min", "Max"], function (a, b) {
        q["_set" + b + s] = function (a) {
          (q["_" + b + s] = j(q, a)) === !1 && alert(F.replace(/%/, b).replace(/_/, a));
        }, q._setOption(b + s, q.options[b + s]);
      });var v = e.SelectedMonth;if (void 0 !== v) {
        var x = j(this, v);b.val(this._formatMonth(new d(f(x), x % 12, 1)));
      }this._updateAlt(), this._setUseInputMask(), this._setDisabledState(), this.Destroy = this.destroy, l ? this.Open() : (b.addClass(u), b.change(L(this._updateAlt, this)));
    }, GetSelectedDate: function GetSelectedDate() {
      return this._parseMonth();
    }, GetSelectedYear: function GetSelectedYear() {
      var a = this.GetSelectedDate();return a ? a.getFullYear() : NaN;
    }, GetSelectedMonth: function GetSelectedMonth() {
      var a = this.GetSelectedDate();return a ? a.getMonth() + 1 : NaN;
    }, Validate: function Validate() {
      var a = this.GetSelectedDate();return null === this.options.ValidationErrorMessage || this.options.Disabled || this._validationMessage.toggle(!a), a;
    }, GetSelectedMonthYear: function GetSelectedMonthYear() {
      var a = this.Validate();return a ? a.getMonth() + 1 + "/" + a.getFullYear() : null;
    }, Disable: function Disable() {
      this._setOption("Disabled", !0);
    }, Enable: function Enable() {
      this._setOption("Disabled", !1);
    }, ClearAllCallbacks: function ClearAllCallbacks() {
      for (var a in this.options) {
        0 === a.indexOf("On") && (this.options[a] = K);
      }
    }, Clear: function Clear() {
      this.element.val(""), a(this.options.AltField).val(""), this._validationMessage.hide();
    }, Toggle: function Toggle(a) {
      return this._visible ? this.Close(a) : this.Open(a);
    }, Open: function Open(b) {
      var d = this.element,
          e = this.options;if (!e.Disabled && !this._visible) {
        if (b = b || a.Event(), k("OnBeforeMenuOpen", this)(b) === !1 || b.isDefaultPrevented()) return;this._visible = !0, this._ajustYear(e);var f = this._monthPickerMenu;if (this._showMonths(), o(d)) f.css("position", "static").show(), k("OnAfterMenuOpen", this)();else {
          G && G.Close(b), G = this, a(c).on("mousedown" + t + this.uuid, L(this.Close, this)).on("keydown" + t + this.uuid, L(this._keyDown, this)), d.off("blur" + t).focus();var g = e.ShowAnim || e.Animation,
              h = "none" === g;f[h ? "fadeIn" : g]({ duration: h ? 0 : this._duration(), start: L(this._position, this, f), complete: k("OnAfterMenuOpen", this) });
        }
      }
    }, Close: function Close(b) {
      var d = this.element;if (!o(d) && this._visible) {
        var e = this._monthPickerMenu,
            f = this.options;if (b = b || a.Event(), k("OnBeforeMenuClose", this)(b) === !1 || b.isDefaultPrevented()) return;this._backToYear && (this._applyJumpYearsHint(), this._backToYear = 0), this._visible = !1, G = null, a(c).off("keydown" + t + this.uuid).off("mousedown" + t + this.uuid), this.Validate(), d.on("blur" + t, L(this.Validate, this));var g = k("OnAfterMenuClose", this),
            h = f.HideAnim || f.Animation;"none" === h ? e.hide(0, g) : e[h](this._duration(), g);
      }
    }, MonthInputFormat: "yy-mm", ParseMonth: function ParseMonth(a, b) {
      try {
        return M.parseDate("dd" + b, "01" + a);
      } catch (c) {
        return null;
      }
    }, FormatMonth: function FormatMonth(a, b) {
      try {
        return M.formatDate(b, a) || null;
      } catch (c) {
        return null;
      }
    }, _setSelectedMonth: function _setSelectedMonth(a) {
      var b = j(this, a),
          c = this.element;if (b) {
        var e = new d(f(b), b % 12, 1);c.val(this._formatMonth(e)), this._updateAlt(0, e), this._validationMessage.hide();
      } else this.Clear();this._ajustYear(this.options), this._showMonths();
    }, _applyJumpYearsHint: function _applyJumpYearsHint() {
      p(this._titleButton, this._i18n("jumpYears"));
    }, _i18n: function _i18n(b) {
      var c = this.options.i18n[b];return "undefined" == typeof c ? a.MonthPicker.i18n[b] : c;
    }, _parseMonth: function _parseMonth(a, b) {
      return this.ParseMonth(a || this.element.val(), b || this.options.MonthFormat);
    }, _formatMonth: function _formatMonth(a, b) {
      return this.FormatMonth(a || this._parseMonth(), b || this.options.MonthFormat);
    }, _updateButton: function _updateButton() {
      var a = this.options.Disabled;this._createButton();var b = this._monthPickerButton;try {
        b.jqueryUIButton("option", "disabled", a);
      } catch (c) {
        b.filter("button,input").prop("disabled", a);
      }this._updateFieldEvents();
    }, _createButton: function _createButton() {
      var b = this.element,
          d = this.options;if (!o(b)) {
        var e = this._monthPickerButton.off(t),
            f = d.ShowIcon ? d.Button : !1;if (a.isFunction(f)) {
          var g = a.extend(!0, { i18n: a.extend(!0, {}, a.MonthPicker.i18n) }, this.options);f = f.call(b[0], g);
        }var h = !1;this._monthPickerButton = (f instanceof a ? f : a(f)).each(function () {
          a.contains(c.body, this) || (h = !0, a(this).insertAfter(b));
        }).on(N, L(this.Toggle, this)).on("mousedown" + t, function (a) {
          a.preventDefault();
        }), this._removeOldBtn && e.remove(), this._removeOldBtn = h;
      }
    }, _updateFieldEvents: function _updateFieldEvents() {
      var a = N + " focus" + t;this.element.off(a), "both" !== this.options.ShowOn && this._monthPickerButton.length || this.element.on(a, L(this.Open, this));
    }, _createValidationMessage: function _createValidationMessage() {
      var b = this.options.ValidationErrorMessage,
          c = this.element;if (-1 === a.inArray(b, [null, ""])) {
        var d = a('<span id="MonthPicker_Validation_' + c[0].id + '" class="month-picker-invalid-message">' + b + "</span>"),
            e = this._monthPickerButton;this._validationMessage = d.insertAfter(e.length ? e : c), c.on("blur" + t, L(this.Validate, this));
      } else this._validationMessage.remove();
    }, _setRTL: function _setRTL(a) {
      n(this._prevButton.css("float", a ? "right" : "left"), !a), n(this._nextButton.css("float", a ? "left" : "right"), a);
    }, _keyDown: function _keyDown(a) {
      switch (a.keyCode) {case 13:
          this.element.val() || this._chooseMonth(new d().getMonth() + 1), this.Close(a);break;case 27:case 9:
          this.Close(a);}
    }, _duration: function _duration() {
      var b = this.options.Duration;return a.isNumeric(b) ? b : b in s ? s[b] : s._default;
    }, _position: H ? function (b) {
      var c = this.options.IsRTL ? C : B,
          d = a.extend(c, this.options.Position);return b.position(a.extend({ of: this.element }, d));
    } : function (a) {
      var b = this.element,
          c = { top: b.offset().top + b.height() + 7 + "px" };return this.options.IsRTL ? c.left = b.offset().left - a.width() + b.width() + 7 + "px" : c.left = b.offset().left + "px", a.css(c);
    }, _setUseInputMask: function _setUseInputMask() {
      if (!this._isMonthInputType) try {
        this.options.UseInputMask ? this.element.mask(this._formatMonth(new d()).replace(/\d/g, 9)) : this.element.unmask();
      } catch (a) {}
    }, _setDisabledState: function _setDisabledState() {
      var a = this.options.Disabled,
          b = this.element;b[0].disabled = a, b.toggleClass(x, a), a && this._validationMessage.hide(), this.Close(), this._updateButton(), k("OnAfterSetDisabled", this)(a);
    }, _getPickerYear: function _getPickerYear() {
      return this._pickerYear;
    }, _setPickerYear: function _setPickerYear(a) {
      this._pickerYear = a || new d().getFullYear(), this._titleButton.jqueryUIButton({ label: this._i18n("year") + " " + this._pickerYear });
    }, _updateAlt: function _updateAlt(b, c) {
      var d = a(this.options.AltField);d.length && d.val(this._formatMonth(c, this.options.AltFormat));
    }, _chooseMonth: function _chooseMonth(b) {
      var c = this._getPickerYear(),
          e = new d(c, b - 1);this.element.val(this._formatMonth(e)).blur(), this._updateAlt(0, e), h(this._selectedBtn, !1), this._selectedBtn = h(a(this._buttons[b - 1]), !0), k("OnAfterChooseMonth", this)(e);
    }, _chooseYear: function _chooseYear(a) {
      this._backToYear = 0, this._setPickerYear(a), this._buttons.removeClass(y), this._showMonths(), this._applyJumpYearsHint(), k("OnAfterChooseYear", this)();
    }, _showMonths: function _showMonths() {
      var b = this._i18n("months");this._prevButton.attr("title", this._i18n("prevYear")).off(N).on(N, L(this._addToYear, this, -1)), this._nextButton.attr("title", this._i18n("nextYear")).off(N).on(N, L(this._addToYear, this, 1)), this._buttons.off(t);var c = this,
          d = L(c._onMonthClick, c);a.each(b, function (b, e) {
        a(c._buttons[b]).on(N, { month: b + 1 }, d).jqueryUIButton("option", "label", e);
      }), this._decorateButtons();
    }, _showYearsClickHandler: function _showYearsClickHandler() {
      if (this._buttons.removeClass(y), this._backToYear) this._setPickerYear(this._backToYear), this._applyJumpYearsHint(), this._showMonths(), this._backToYear = 0;else {
        this._backToYear = this._getPickerYear(), this._showYears();var a = this._i18n("backTo") + " " + this._getPickerYear();this._titleButton.jqueryUIButton({ label: a }).data(v)(), k("OnAfterChooseYears", this)();
      }
    }, _showYears: function _showYears() {
      var b = this._getPickerYear(),
          c = -4,
          e = b + c,
          g = 12,
          j = new d().getFullYear(),
          k = this._MinMonth,
          l = this._MaxMonth,
          m = k ? f(k) : 0,
          n = l ? f(l) : 0;this._prevButton.attr("title", this._i18n("prev12Years")).off(N).on(N, L(this._addToYears, this, -g)), this._nextButton.attr("title", this._i18n("next12Years")).off(N).on(N, L(this._addToYears, this, g)), q(this._prevButton, m && m > e - 1), q(this._nextButton, n && e + 12 - 1 > n), this._buttons.off(t), h(this._selectedBtn, !1);for (var o = this.GetSelectedYear(), p = L(this._onYearClick, this), r = i(j, m, n), s = i(o, m, n), u = 0; 12 > u; u++) {
        var v = b + c,
            w = a(this._buttons[u]).jqueryUIButton({ disabled: !i(v, m, n), label: v }).toggleClass(y, v === j && r).on(N, { year: v }, p);s && o && o === v && (this._selectedBtn = h(w, !0)), c++;
      }
    }, _onMonthClick: function _onMonthClick(a) {
      this._chooseMonth(a.data.month), this.Close(a);
    }, _onYearClick: function _onYearClick(a) {
      this._chooseYear(a.data.year);
    }, _addToYear: function _addToYear(a) {
      this._setPickerYear(this._getPickerYear() + a), this.element.focus(), this._decorateButtons(), k("OnAfter" + (a > 0 ? "Next" : "Previous") + "Year", this)();
    }, _addToYears: function _addToYears(a) {
      this._pickerYear = this._getPickerYear() + a, this._showYears(), this.element.focus(), k("OnAfter" + (a > 0 ? "Next" : "Previous") + "Years", this)();
    }, _ajustYear: function _ajustYear(a) {
      var b = a.StartYear || this.GetSelectedYear() || new d().getFullYear();null !== this._MinMonth && (b = Math.max(f(this._MinMonth), b)), null !== this._MaxMonth && (b = Math.min(f(this._MaxMonth), b)), this._setPickerYear(b);
    }, _decorateButtons: function _decorateButtons() {
      var b = this._getPickerYear(),
          c = e(new d()),
          g = this._MinMonth,
          j = this._MaxMonth;h(this._selectedBtn, !1);var k = this.GetSelectedDate(),
          l = i(k ? e(k) : null, g, j);k && k.getFullYear() === b && (this._selectedBtn = h(a(this._buttons[k.getMonth()]), l)), q(this._prevButton, g && b == f(g)), q(this._nextButton, j && b == f(j));for (var m = 0; 12 > m; m++) {
        var n = 12 * b + m,
            o = i(n, g, j);a(this._buttons[m]).jqueryUIButton({ disabled: !o }).toggleClass(y, o && n == c);
      }
    } });
}(jQuery, window, document, Date);